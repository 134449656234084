import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import {
  getBuildingList,
  getCommunityList,
  getNewNumber,
  getNewNumberPercentage,
  getOwnerList,
  getProjectList,
  getQueryParamsObj,
} from '@/api'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import moment from 'moment/moment'
import LoadingBox from '@/components/baseMap/loadingBox'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.9vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.6vh',
    },
  },
}))
const StyledStackTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.9vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '15vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    border: 0,
    '@media (max-width: 1280px)': {
      fontSize: '1.6vh',
    },
    '&>div': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    '@media (max-width: 1280px)': {
      height: '6%',
      padding: 0,
      paddingBottom: 10,
      flexDirection: 'column',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    flex: 1,
    width: '100%',
    '&>div:nth-of-type(1)': {
      marginLeft: 0,
    },
    '&>div:last-child': {
      '@media (max-width: 1280px)': {
        margin: 0,
      },
    },
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '100%',
    },
  },
  selectBigBox: {
    width: '20%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      marginLeft: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '12vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  selectBox: {
    width: '75%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  iphoneBtnBox: {
    width: '35%',
    textAlign: 'end',
  },
  content: {
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    padding: 10,
    height: '25%',
    color: '#000',
    position: 'relative',
    '@media (max-width: 1280px)': {
      height: '22%',
    },
  },
  tableBox: {
    maxHeight: 'calc(63% - 15px)',
    display: 'flex',
    overflow: 'auto',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      maxHeight: 'calc(67% - 15px)',
    },
  },
  breadcrumbBox: {
    height: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>nav': {
      fontSize: '2.5vh',
      '@media (max-width: 1280px)': {
        fontSize: '2vh',
      },
      '&>ol>li>p': {
        fontSize: '2.5vh',
        '@media (max-width: 1280px)': {
          fontSize: '2vh',
        },
      },
      '&>ol>li>a': {
        color: '#1E90FF',
        textDecoration: 'none',
        borderBottom: '1px solid #1E90FF',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  cardBox: {
    padding: 10,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    height: '100%',
    overflow: 'auto',
    color: '#000',
  },
  cardBoxWidth1: {
    width: '100%',
  },
  cardBoxWidth2: {
    width: '83%',
  },
  tableBody: {
    border: '1px solid #000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    fontSize: '1.5vh',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableBody1: {
    border: '1px solid #000',
    borderTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  detailsBox: {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: '1px solid #ccc',
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    width: '8.7vw',
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
      width: '16.2vw',
    },
  },
  collapseBox: {
    fontSize: '1.5vh',
    color: '#000',
  },
  thBoxHover: {
    color: '#4169e1',
    borderBottom: '2px solid #4169e1',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        color: '#214162',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
    },
  },
  loadingBox: {
    position: 'absolute',
    top: 30,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
}))

interface PropertyDetailsProps {
  divWidth: any
  urlName: any
}

const PropertyDetails = (props: PropertyDetailsProps) => {
  const { divWidth, urlName } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const shadowBoo = false
  const [arrList, setArrList] = useState<any>([])
  const [loadingBoo, setLoadingBoo] = useState<any>(true)
  const [checked, setChecked] = useState(false)
  const [stackBoo, setStackBoo] = useState(false)
  const [titleBoo, setTitleBoo] = useState(true)
  const [communityObj, setCommunityObj] = useState<any>('')
  const [typographyValue, setTypographyValue] = useState('')
  const [selectValue, setSelectValue] = useState('')
  const [ytBuildingIdValue, setYtBuildingIdValue] = useState('')
  const [addressValue, setAddressValue] = useState('')
  const [typeValue, setTypeValue] = useState('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const propertyCommunity = getQueryParamsObj(window.location?.search)

  const headerArr = [
    {
      key: 1,
      name: '地址',
      width: '10%',
    },
    {
      key: 2,
      name: '当年应收',
      width: '10%',
    },
    {
      key: 3,
      name: '当年预收',
      width: '10%',
    },
    {
      key: 4,
      name: '收当年',
      width: '10%',
    },
    {
      key: 5,
      name: '收上年欠',
      width: '10%',
    },
    {
      key: 6,
      name: '收历年欠',
      width: '10%',
    },
    {
      key: 7,
      name: '当年欠',
      width: '10%',
    },
    {
      key: 8,
      name: '上年欠',
      width: '10%',
    },
    {
      key: 9,
      name: '历年欠',
      width: '10%',
    },
    {
      key: 10,
      name: '上年预收',
      width: '10%',
    },
  ]

  const iphoneHeaderArr = [
    {
      key: 1,
      name: '地址',
      width: '23.2%',
    },
    {
      key: 2,
      name: '收当年',
      width: '19.2%',
    },
    {
      key: 3,
      name: '当年欠',
      width: '19.2%',
    },
    {
      key: 4,
      name: '收历年',
      width: '19.2%',
    },
    {
      key: 5,
      name: '历年欠',
      width: '19.2%',
    },
  ]

  const communityHeaderArr = [
    {
      key: 1,
      name: '',
      width: '25%',
    },
    {
      key: 2,
      name: '指标数',
      width: '25%',
    },
    {
      key: 3,
      name: '完成数',
      width: '25%',
    },
    {
      key: 4,
      name: '完成度',
      width: '25%',
    },
  ]

  const typeObj: any = {
    '5': '管理费',
    '6': '租金',
    '7': '商铺管理费',
    '8': '停车费',
    '17': '管理费',
    '18': '租金',
    '19': '商铺管理费',
    '20': '停车费',
  }

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getData = (str: any, accountType: any) => {
    getBuildingList(str, accountType, shadowBoo)
      .then((res: any) => {
        setArrList(res?.data)
        setStackBoo(false)
      })
      .catch(console.error)
  }

  const getNewArr = (str: any, accountType: any, shadow: any, ytBuildingId: any, address: any) => {
    getOwnerList(str, accountType, shadow, ytBuildingId, address).then((res: any) => {
      setArrList(res?.data)
      setStackBoo(false)
    })
  }

  const getCommunityData = (obj: any) => {
    getCommunityList(moment.utc(new Date()).format('YYYY'), 12, obj?.id, typeObj[obj?.typeValue]).then((res: any) => {
      const overdueObj = res?.overdue
      const metricsObj = res?.metrics
      const incomeSObj = res?.incomes
      const newArr: any = []
      const allArr: any = []
      overdueObj?.map((item: any) => {
        const childObj: any = metricsObj[item.projectId]?.filter(
          (items: any) => items.assessType === typeObj[obj?.typeValue],
        )[0]
        newArr.push({
          year: item.year,
          projectId: item.projectId,
          projectName: item.projectName,
          chargeName: item.chargeName,
          currentYearAccountsReceivable: item.currentYearAccountsReceivable,
          currentYearIncomeMetric: childObj?.currentYearMetric,
          overYearsIncomeMetric: childObj?.overYearMetric,
        })
      })
      newArr?.map((item: any) => {
        let historicalArrears: any = 0
        let preYearArrears: any = 0
        let currentYearArrears: any = 0
        let currentMonthActual: any = 0
        let currentYearPrepaidActual: any = 0
        let preYearAllocCurrentMonth: any = 0
        incomeSObj?.map((items: any) => {
          if (item.projectId === items.projectId && items?.incomeName !== '临时停车费') {
            historicalArrears += items.historicalArrears
            preYearArrears += items.preYearArrears
            currentYearArrears += items.currentYearArrears
            currentMonthActual += items.currentMonthActual
            currentYearPrepaidActual += items.currentYearPrepaidActual
            preYearAllocCurrentMonth += items.preYearAllocCurrentMonth
          }
        })
        allArr.push({
          ...item,
          historicalArrears,
          preYearArrears,
          currentYearArrears,
          currentMonthActual,
          currentYearPrepaidActual,
          preYearAllocCurrentMonth,
        })
      })
      const listObj: any = allArr?.filter((item: any) => item.projectId === obj?.id)[0]
      setCommunityObj(listObj || '')
      setLoadingBoo(false)
    })
  }

  useEffect(() => {
    if (propertyCommunity?.typeValue) {
      setStackBoo(false)
      setAddressValue('')
      setTypographyValue(arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name || '')
      setTypeValue(typeObj[propertyCommunity?.typeValue])
    }
  }, [propertyCommunity?.typeValue, arrParkingLotList])

  useEffect(() => {
    if (propertyCommunity?.id) {
      setSelectValue(propertyCommunity?.id)
      setTypographyValue(arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name || '')
    }
  }, [propertyCommunity?.id])

  useEffect(() => {
    if (
      propertyCommunity?.typeValue &&
      propertyCommunity?.id &&
      ((Number(propertyCommunity?.typeValue) > 4 && Number(propertyCommunity?.typeValue) < 9) ||
        (Number(propertyCommunity?.typeValue) > 16 && Number(propertyCommunity?.typeValue) < 21))
    ) {
      getCommunityData({ ...propertyCommunity })
      setArrList([])
      setStackBoo(true)
      setTitleBoo(true)
      setLoadingBoo(true)
      getData(propertyCommunity?.id, typeObj[propertyCommunity?.typeValue])
    }
  }, [propertyCommunity?.typeValue, propertyCommunity?.id])

  useEffect(() => {
    getParkingLotArr()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {communityHeaderArr?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingBoo ? (
              <div className={classes.loadingBox}>
                <LoadingBox />
              </div>
            ) : (
              <>
                <StyledTableRow>
                  <StyledTableCell align="left">当年</StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj?.currentYearIncomeMetric, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(
                      communityObj?.currentYearArrears +
                        communityObj?.currentMonthActual +
                        communityObj?.currentYearPrepaidActual +
                        communityObj?.preYearAllocCurrentMonth,
                      2,
                    ) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${communityObj?.currentYearIncomeMetric}` !== '0'
                      ? `${getNewNumberPercentage(
                          (communityObj?.currentYearArrears +
                            communityObj?.currentMonthActual +
                            communityObj?.currentYearPrepaidActual +
                            communityObj?.preYearAllocCurrentMonth) /
                            communityObj?.currentYearIncomeMetric,
                          2,
                        )}`
                      : `0%`}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">旧欠</StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj?.overYearsIncomeMetric, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj.preYearArrears + communityObj.historicalArrears, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${communityObj?.overYearsIncomeMetric}` !== '0'
                      ? `${getNewNumberPercentage(
                          (communityObj.preYearArrears + communityObj.historicalArrears) /
                            communityObj?.overYearsIncomeMetric,
                          2,
                        )}`
                      : `0%`}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">总计</StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(communityObj?.currentYearIncomeMetric + communityObj?.overYearsIncomeMetric, 2) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getNewNumber(
                      communityObj?.currentYearArrears +
                        communityObj?.currentMonthActual +
                        communityObj?.currentYearPrepaidActual +
                        communityObj.preYearArrears +
                        communityObj.historicalArrears +
                        communityObj?.preYearAllocCurrentMonth,
                      2,
                    ) || 0}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${communityObj?.overYearsIncomeMetric + communityObj?.currentYearIncomeMetric}` !== '0'
                      ? `${getNewNumberPercentage(
                          (communityObj?.currentYearArrears +
                            communityObj?.currentMonthActual +
                            communityObj?.currentYearPrepaidActual +
                            communityObj.preYearArrears +
                            communityObj.historicalArrears +
                            communityObj?.preYearAllocCurrentMonth) /
                            (communityObj?.overYearsIncomeMetric + communityObj?.currentYearIncomeMetric),
                          2,
                        )}`
                      : `0%`}
                  </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          {divWidth > 1280 && !urlName?.includes('management') && (
            <div className={classes.selectBigBox}>
              <div className={classes.titleText}>小区：</div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setYtBuildingIdValue('')
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {`${item.name}-${item.id}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>地址：</div>
            <div className={classes.selectBox}>
              <TextField
                className={classes.iptModal}
                value={addressValue}
                onChange={(event: any) => {
                  setAddressValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
            </div>
            {divWidth <= 1280 && (
              <div className={classes.iphoneBtnBox}>
                <Button
                  onClick={() => {
                    setStackBoo(true)
                    setLoadingBoo(true)
                    setTitleBoo(
                      (typographyValue ===
                        arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name ||
                        typographyValue === '') &&
                        addressValue === '',
                    )
                    if (addressValue !== '') {
                      getNewArr(selectValue, typeValue, shadowBoo, ytBuildingIdValue, addressValue)
                    } else {
                      if (ytBuildingIdValue !== '') {
                        getNewArr(selectValue, typeValue, shadowBoo, ytBuildingIdValue, addressValue)
                      } else {
                        getData(selectValue, typeValue)
                      }
                    }
                  }}
                  variant="contained"
                  className={classes.btn}
                >
                  查询
                </Button>
              </div>
            )}
          </div>
        </div>
        {divWidth > 1280 && (
          <div>
            <Button
              onClick={() => {
                setStackBoo(true)
                if (propertyCommunity?.id !== selectValue && addressValue === '') {
                  setTitleBoo(true)
                } else {
                  setTitleBoo(
                    (typographyValue === arrParkingLotList?.filter((res: any) => res.id === selectValue)[0]?.name ||
                      typographyValue === '') &&
                      addressValue === '',
                  )
                }
                navigate(`?id=${selectValue}&typeValue=${propertyCommunity?.typeValue}`)
                if (addressValue !== '') {
                  getNewArr(selectValue, typeValue, shadowBoo, ytBuildingIdValue, addressValue)
                } else {
                  if (ytBuildingIdValue !== '') {
                    getNewArr(selectValue, typeValue, shadowBoo, ytBuildingIdValue, addressValue)
                  } else {
                    getData(selectValue, typeValue)
                  }
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              查询
            </Button>
            {divWidth > 1280 && (
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => {
                  navigate(
                    `${urlName}${propertyCommunity?.typeValue}?id=${selectValue}&typeValue=${propertyCommunity?.typeValue}`,
                  )
                }}
                variant="contained"
                className={classes.btn}
              >
                分享
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={classes.breadcrumbBox} role="presentation">
        {typographyValue === arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name ||
        typographyValue === '' ? (
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">{typographyValue}</Typography>
          </Breadcrumbs>
        ) : (
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                setStackBoo(true)
                if (addressValue) {
                  titleBoo && setTitleBoo(false)
                  getNewArr(selectValue, typeValue, shadowBoo, '', addressValue)
                } else {
                  !titleBoo && setTitleBoo(true)
                  getData(selectValue, typeValue)
                }
                setYtBuildingIdValue('')
                setTypographyValue(arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name)
              }}
            >
              {arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]?.name}
            </Link>
            <Typography color="text.primary">{typographyValue}</Typography>
          </Breadcrumbs>
        )}
        <FormGroup>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={checked}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
                color="default"
              />
            }
            label="只看欠费"
          />
        </FormGroup>
      </div>
      <div className={classes.tableBox}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {(divWidth > 1280 ? headerArr : iphoneHeaderArr)?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          {stackBoo ? (
            <TableBody>
              <StyledTableRow>
                <StyledStackTableCell colSpan={(divWidth > 1280 ? headerArr : iphoneHeaderArr)?.length}>
                  <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                  </Stack>
                </StyledStackTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(checked
                ? arrList?.filter(
                    (res: any) => res.lastYearOverdue + res.overYearsOverdue !== 0 || res.currentYearOverdue !== 0,
                  )
                : arrList
              )?.map((item: any, i: number) => {
                return divWidth > 1280 ? (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">
                      {titleBoo ? (
                        <div className={classes.thBox}>
                          <span
                            onClick={() => {
                              if (addressValue === '') {
                                if (
                                  typographyValue ===
                                    arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]
                                      ?.name ||
                                  typographyValue === ''
                                ) {
                                  setTitleBoo(false)
                                  setStackBoo(true)
                                  setYtBuildingIdValue(item.buildingId)
                                  getNewArr(selectValue, typeValue, shadowBoo, item.buildingId, addressValue)
                                  setTypographyValue(`${item?.houseAddress2}${item.houseAddress3}`)
                                }
                              }
                            }}
                            className={classes.thBoxHover}
                          >{`${item.houseAddress2}${item.houseAddress3}`}</span>
                        </div>
                      ) : (
                        <div className={classes.thBox}>{`${item.houseAddress3}${item.houseNum}`}</div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearAccountsReceivable, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearPrepayment, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearIncome, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.lastYearOverdueIncome, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.overYearsOverdueIncome, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.lastYearOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.overYearsOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.prepayCurrentYear, 2) || 0}</div>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">
                      {titleBoo ? (
                        <div style={{ width: '21.4vw' }} className={classes.thBox}>
                          <span
                            onClick={() => {
                              if (addressValue === '') {
                                if (
                                  typographyValue ===
                                    arrParkingLotList?.filter((res: any) => res.id === propertyCommunity?.id)[0]
                                      ?.name ||
                                  typographyValue === ''
                                ) {
                                  setTitleBoo(false)
                                  setStackBoo(true)
                                  setYtBuildingIdValue(item.buildingId)
                                  getNewArr(selectValue, typeValue, shadowBoo, item.buildingId, addressValue)
                                  setTypographyValue(`${item?.houseAddress2}${item.houseAddress3}`)
                                }
                              }
                            }}
                            className={classes.thBoxHover}
                          >
                            {`${item.houseAddress2}${item.houseAddress3}`.substring(
                              `${item.houseAddress2}${item.houseAddress3}`?.length - (selectValue === '101' ? 6 : 8),
                              `${item.houseAddress2}${item.houseAddress3}`?.length,
                            )}
                          </span>
                        </div>
                      ) : (
                        <div style={{ width: '21.4vw' }} className={classes.thBox}>
                          {`${item.houseAddress3}${item.houseNum}`}
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>
                        {getNewNumber(item.currentYearIncome + item.prepayCurrentYear, 2) || 0}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{getNewNumber(item.currentYearOverdue, 2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>
                        {getNewNumber(item.lastYearOverdueIncome + item.overYearsOverdueIncome, 2) || 0}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>
                        {getNewNumber(item.lastYearOverdue + item.overYearsOverdue, 2) || 0}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </div>
  )
}

export default PropertyDetails
